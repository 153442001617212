export function processImagePaste(
  e: ClipboardEvent,
  acceptedMime: string[] = ["jpg", "jpeg", "gif", "png"],
) {
  const items = e.clipboardData?.items ?? [];
  const returnedBlobs = [];

  for (const item of items) {
    const [type, format] = item.type.split("/");

    if (
      item.kind === "file" &&
      type === "image" &&
      acceptedMime.some((ext) => ext.includes(format.toLowerCase()))
    ) {
      const blob = item.getAsFile();
      if (blob) returnedBlobs.push(blob);
    }
  }

  return returnedBlobs;
}
