import type { CommentEntity } from "~/src/api";
import type { CommentableResource } from "~/src/api";
import type { SessionFile } from "~/src/api";
import type { CommentPostBody } from "~/src/api";
import { nanoid } from "nanoid";
import { removeUnchanged } from "./heplers";
import type { AxiosProgressEvent } from "axios";
import { Comment, isOrg } from "~/src/api";
import type { LocalFile } from "~/src/api";

export class CommentTemplate {
  template: CommentPostBody & {
    media?: SessionFile | null;
  } = {
    message: "",
    spoiler: false,
    organizationId: undefined,
  };
  initial?: CommentEntity;
  feedType: CommentableResource;
  feedId: string;

  constructor(
    feedType: CommentableResource,
    feedId: string,
    initial?: CommentEntity,
  ) {
    this.feedType = feedType;
    this.feedId = feedId;

    if (initial) this.parseFromComment(initial);
  }

  static parseFromComment(comment: CommentEntity): CommentPostBody {
    const org = comment.relationships.find(isOrg);

    return {
      message: comment.attributes.message ?? "",
      spoiler: comment.attributes.spoiler,
      organizationId: org?.id,
    };
  }

  parseFromComment(comment: CommentEntity) {
    const { getImageUrl } = useMediaLink();

    this.initial = comment;
    this.template = CommentTemplate.parseFromComment(comment);

    if (comment.attributes.media) {
      this.template.media = {
        source: "remote",
        id: nanoid(),
        filename: comment.attributes.media,
        blobUrl: getImageUrl(
          comment.id,
          "comment",
          "media",
          comment.attributes.media,
        )!,
        index: 0,
      };
    }
  }

  getBody(): CommentPostBody {
    let template = {
      ...this.template,
      media: undefined,
    } as CommentPostBody;

    template = removeUnchanged(
      template,
      this.initial ? CommentTemplate.parseFromComment(this.initial) : undefined,
    );

    return template;
  }

  async updateMetadata(auth: string) {
    const postBody = this.getBody();

    let resp;
    if (!this.initial) {
      resp = await Comment.create(this.feedType, this.feedId, postBody, auth);
    } else if (Object.keys(postBody).length > 0) {
      // todo: be does not allow unmarking of spoilers via edit
      resp = await Comment.update(
        this.initial.id,
        {
          message: this.getBody().message,
        },
        this.initial.attributes.version,
        auth,
      );
    } else resp = this.initial;

    this.initial = resp;
    this.template = {
      ...this.template,
      ...CommentTemplate.parseFromComment(resp),
    };
    return resp;
  }

  async deleteMedia(auth: string) {
    if (!this.initial)
      throw new Error("Cannot delete media without initial comment");

    await Comment.deleteMedia(
      this.initial.id,
      this.initial.attributes.version,
      auth,
    );

    // todo: comment delete doesnt return new comment entity
    this.initial.attributes.media = null;
    this.initial.attributes.version += 1;
    return this.initial;
  }

  async updateMedia(
    auth: string,
    onUploadProgress?: (e: AxiosProgressEvent) => any,
  ) {
    if (!this.initial)
      throw new Error("Cannot update media without initial comment");

    const shouldUpdateMedia = this.template.media?.source === "local";
    const shouldDeleteMedia = this.template.media === null;

    let resp = this.initial;
    if (shouldUpdateMedia) {
      if (this.initial.attributes.media) {
        await this.deleteMedia(auth);
      }

      resp = await Comment.uploadMedia(
        this.initial.id,
        (this.template.media as LocalFile).data,
        this.initial.attributes.version,
        auth,
        onUploadProgress,
      );
    } else if (shouldDeleteMedia) {
      resp = await this.deleteMedia(auth);
    }

    if (resp.attributes.media) {
      const { getImageUrl } = useMediaLink();
      this.template.media = {
        source: "remote",
        id: nanoid(),
        filename: resp.attributes.media,
        blobUrl: getImageUrl(
          resp.id,
          "comment",
          "media",
          resp.attributes.media,
        )!,
        index: 0,
      };
    }

    return resp;
  }

  async update(
    auth: string,
    onUploadProgress?: (e: AxiosProgressEvent) => any,
  ): Promise<CommentEntity> {
    await this.updateMetadata(auth);
    return this.updateMedia(auth, onUploadProgress);
  }

  async create(
    auth: string,
    onUploadProgress?: (e: AxiosProgressEvent) => any,
  ): Promise<CommentEntity> {
    if (this.initial) throw new Error("Comment has already been created");
    return this.update(auth, onUploadProgress);
  }

  async submit(
    token: string,
    onUploadProgress?: (e: AxiosProgressEvent) => any,
  ) {
    if (!this.initial) {
      return this.create(token, onUploadProgress);
    }
    return this.update(token, onUploadProgress);
  }

  reset(clearInitial = false) {
    if (clearInitial) {
      this.initial = undefined;
    }

    if (this.template.media?.source === "local") {
      URL.revokeObjectURL(this.template.media.blobUrl);
    }

    if (this.initial) {
      this.parseFromComment(this.initial);
    } else
      this.template = {
        message: "",
        spoiler: false,
        organizationId: undefined,
      };
  }
}
