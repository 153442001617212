<template>
  <div
    v-bind="$attrs"
    class="grid hover:bg-neutral-100 dark:hover:bg-neutral-800 p-2 rounded-md cursor-pointer transition"
    @click="() => (showAuthorOptionsModal = true)"
  >
    <div class="pointer-events-none min-w-0">
      <slot v-if="modelValue === null">
        <UserCard
          v-if="user"
          :user="user"
          :badges="user.relationships.filter(isBadge).filter(isExpanded)"
          class="!py-0 min-w-0 !font-base"
          no-link
          :avatar-size="avatarSize || 48"
          plain
        >
          <template #postDisplayName>
            <span class="ml-auto flex-shrink-0">
              <IconChevronDown />
            </span>
          </template>
          <template #subtext>
            <slot name="subtext"></slot>
          </template>
        </UserCard>
      </slot>
      <slot v-else name="selectedOrg" v-bind="{ selectedOrg }">
        <OrganizationCardDense
          v-if="selectedOrg"
          :org="selectedOrg"
          class="!py-0 min-w-0"
          no-link
          :avatar-size="avatarSize || 48"
          plain
        >
          <template #postDisplayName>
            <span class="ml-auto pl-1 flex-shrink-0">
              <IconChevronDown />
            </span>
          </template>
          <template #subtext>
            <slot name="subtext"></slot>
          </template>
        </OrganizationCardDense>
      </slot>
    </div>
  </div>
  <NamiModal v-model="showAuthorOptionsModal" large>
    <NamiModalTitle @close="showAuthorOptionsModal = false"
      >Post Options
    </NamiModalTitle>
    <div class="space-y-2">
      <div class="text-lg font-medium">Post As</div>
      <HeadlessRadioGroup
        :modelValue="modelValue ?? ''"
        @update:modelValue="selectOrg"
      >
        <HeadlessRadioGroupOption v-if="user" v-slot="{ checked }" value="">
          <div
            class="grid grid-cols-[1fr_auto] items-center cursor-pointer p-2 gap-2 rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-700 transition"
          >
            <UserCard class="!py-0 min-w-0" :user="user" no-link />
            <IconCircleFilled v-if="checked" class="text-nami-comi-blue" />
            <IconCircle v-else />
          </div>
        </HeadlessRadioGroupOption>
        <NamiLoading class="mx-auto" v-if="pending && !orgs.length" />
        <HeadlessRadioGroupOption
          v-for="org in orgs"
          v-slot="{ checked }"
          :value="org.id"
        >
          <div
            class="grid grid-cols-[1fr_auto] items-center cursor-pointer p-2 gap-2 rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-700 transition"
          >
            <OrganizationCardDense class="!py-0 min-w-0" :org="org" no-link />
            <IconCircleFilled v-if="checked" class="text-nami-comi-blue" />
            <IconCircle v-else />
          </div>
        </HeadlessRadioGroupOption>
      </HeadlessRadioGroup>
    </div>
  </NamiModal>
</template>

<script setup lang="ts">
import {
  IconChevronDown,
  IconCircle,
  IconCircleFilled,
} from "@tabler/icons-vue";
import { type UserEntity, isBadge, isExpanded } from "~/src/api";
import { useUserOrgsWithPerms } from "~/composables/organization/getByPermissions";
import OrganizationCardDense from "~/components/organization/OrganizationCardDense.vue";

const props = defineProps<{
  modelValue: string | null;
  avatarSize?: number;
  user?: UserEntity;
  sync?: boolean;
  // default 'synced-social-author'
  syncKey?: string;
  permCheck?: "comment" | "post";
}>();

const emit = defineEmits<{
  (e: "update:modelValue", id: string | null): void;
}>();
const showAuthorOptionsModal = ref(false);
const selectedOrg = computed(() => {
  if (!props.modelValue) return null;
  return orgs.value.find((org) => org.id === props.modelValue);
});

const syncedOrgId = useState<string | null>(
  props.syncKey ?? "synced-social-author",
  () => null,
);

watch(syncedOrgId, (v) => {
  if (props.sync) selectOrg(v);
});

const {
  data: orgs,
  pending,
  refresh,
  // props.permCheck
} = useUserOrgsWithPerms().value;

const selectOrg = (orgId: string | null) => {
  if (!orgId) {
    if (props.sync) syncedOrgId.value = null;
    emit("update:modelValue", null);
    showAuthorOptionsModal.value = false;
    return;
  }

  const foundOrg = orgs.value.find((org) => org.id === orgId);
  assertDefined(foundOrg);

  emit("update:modelValue", foundOrg.id);
  if (props.sync) syncedOrgId.value = foundOrg.id;
  showAuthorOptionsModal.value = false;
};

watch(showAuthorOptionsModal, (v) => {
  if (v && !orgs.value.length) {
    refresh();
  }
});

onMounted(() => {
  if (props.sync && syncedOrgId.value) {
    if (orgs.value?.some((org) => org.id === syncedOrgId.value)) {
      selectOrg(syncedOrgId.value);
      return;
    }

    const unwatch = watch(
      orgs,
      (list) => {
        if (list?.some((org) => org.id === syncedOrgId.value)) {
          selectOrg(syncedOrgId.value);
          unwatch();
        } else {
          refresh();
        }
      },
      { immediate: true },
    );
  }
});
</script>
