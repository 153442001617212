<template>
  <div
    class="rounded relative grid grid-cols-[40px_1fr] gap-x-4 gap-y-2"
    v-bind="$attrs"
  >
    <div
      v-if="user"
      class="flex justify-between items-start min-w-0 col-span-2"
    >
      <PostAuthorSelect
        class="-m-2 mr-0 items-center"
        :model-value="commentTemplate.template.organizationId || null"
        @update:model-value="
          (v) => (commentTemplate.template.organizationId = v || undefined)
        "
        :user="user"
        :avatar-size="40"
        perm-check="comment"
        :sync="sync"
      />
      <ContextMenuList
        close-on-outside-click
        :options="[
          {
            name: commentTemplate.template.spoiler
              ? translate(
                  'components.feedCommentAddForm.contextMenuUnmarkSpoiler',
                )
              : translate(
                  'components.feedCommentAddForm.contextMenuMarkSpoiler',
                ),
            action: () =>
              (commentTemplate.template.spoiler =
                !commentTemplate.template.spoiler),
            icon: commentTemplate.template.spoiler ? IconEyeOff : IconEye,
          },
        ]"
      >
        <template v-slot="{ isOpen, open, close }">
          <NamiButton
            button-type="secondary"
            :icon="IconDots"
            small
            pill
            text
            @buttonClick="() => (isOpen ? close() : open())"
            :disabled="isPerformingAction"
          />
        </template>
      </ContextMenuList>
    </div>
    <UserAvatar
      v-else
      class="w-12 h-12 border border-neutral-300 dark:border-neutral-700 rounded-full"
      :size="40"
    />
    <div
      class="relative w-full space-y-2 col-start-2"
      :class="{
        'bg-neutral-300 dark:bg-neutral-700 rounded':
          commentTemplate.template.spoiler,
      }"
    >
      <div class="h-full" :class="{ disabled: isPerformingAction }">
        <ClientOnly v-if="user">
          <LazyNamiRichTextEditor
            :model-value="commentTemplate.template.message ?? ''"
            @update:model-value="(v) => (commentTemplate.template.message = v)"
            :placeholder="
              translate('components.feedCommentAddForm.placeholderText')
            "
            @paste="(e) => updateFiles(processImagePaste(e))"
            close-on-sticker-input
            :disabled-emote-types="
              !!commentTemplate.template.media ? ['stickers'] : undefined
            "
            ref="richTextEditor"
          >
            <template #toolbarBefore>
              <NamiButton
                button-type="primary"
                :icon="IconPhotoPlus"
                small
                pill
                text
                @buttonClick="doAddFile"
                :disabled="
                  isPerformingAction ||
                  !!commentTemplate.template.media ||
                  !!richTextEditor?.addedSticker
                "
              />
            </template>
            <template #toolbarAfter>
              <div
                class="!ml-auto text-xs transition-opacity opacity-0 self-center"
                :class="{
                  'opacity-100':
                    commentTemplate.template.message!.length > 2750,
                  'text-red-400':
                    commentTemplate.template.message!.length > 3000,
                }"
              >
                {{ commentTemplate.template.message!.length }}/3000
              </div>
              <slot name="additionalActions" v-bind="{ isPerformingAction }">
              </slot>
              <NamiButton
                button-type="primary"
                small
                pill
                @buttonClick="submit"
                :disabled="
                  isPerformingAction ||
                  commentTemplate.template.message!.length > 3000 ||
                  (!commentTemplate.template.message!.trim() &&
                    !commentTemplate.template.media)
                "
              >
                <span class="px-1 flex items-center">
                  <span class="mr-1">{{
                    $t("components.feedCommentAddForm.buttonText")
                  }}</span>
                  <IconSend :size="18" />
                </span>
              </NamiButton>
            </template>
          </LazyNamiRichTextEditor>
        </ClientOnly>
        <div
          v-else
          class="opacity-70 cursor-text h-full"
          @click="() => appStore?.openLoginRequiredModal()"
        >
          {{ $t("components.feedCommentAddForm.placeholderText") }}
        </div>
        <div
          v-if="commentTemplate.template.media"
          class="flex flex-col min-w-0 relative col-start-2 mt-4"
          :class="{ disabled: isPerformingAction }"
        >
          <div
            class="relative min-h-[8rem] min-w-[8rem] max-h-[8rem] max-w-[8rem]"
          >
            <NamiFile
              class="!absolute top-0 h-full w-full left-0"
              :class="{
                disabled: isPerformingAction,
                'brightness-75': commentTemplate.template.spoiler,
              }"
              :blob-url="commentTemplate.template.media.blobUrl"
              :filename="commentTemplate.template.media.filename"
              :blur="spoilAttachment"
              @remove="clearAttachment"
              @re-crop="() => (visibleRef = true)"
            />
            <!--        <div class="absolute -right-2 top-6">-->
            <!--          <NamiButton-->
            <!--            :buttonType="spoilAttachment ? 'danger' : 'secondary'"-->
            <!--            :icon="spoilAttachment ? IconEyeOff : IconEye"-->
            <!--            pill-->
            <!--            small-->
            <!--            @click="() => spoilAttachment = !spoilAttachment"-->
            <!--            block-->
            <!--          />-->
            <!--        </div>-->
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      accept=".jpg,.jpeg,.gif,.png,.JPG,.JPEG,.GIF,.PNG"
      multiple
      hidden
      ref="fileInput"
      @change="() => updateFiles()"
    />
    <div
      v-if="isPerformingAction"
      class="absolute inset-0 flex flex-col justify-center items-center"
    >
      <NamiLoading class="!h-auto" size="small" />
      <div class="post-progress">
        <div class="relative text-center" style="z-index: 1">
          {{ uploadProgress.toFixed(0) }}%
        </div>
        <div
          class="dark:none absolute top-0 left-0 w-full text-center text-white"
          style="z-index: 1"
          :style="{ clipPath: `inset(0 ${100 - uploadProgress}% 0 0 )` }"
        >
          {{ uploadProgress.toFixed(0) }}%
        </div>
      </div>
    </div>
  </div>
  <VueEasyLightbox
    teleport="body"
    :visible="visibleRef"
    :imgs="[commentTemplate.template.media?.blobUrl ?? '']"
    :index="0"
    @hide="visibleRef = false"
    rotate-disabled
  />
</template>

<script setup lang="ts">
import {
  IconDots,
  IconEye,
  IconEyeOff,
  IconPhotoPlus,
  IconSend,
} from "@tabler/icons-vue";
import VueEasyLightbox from "vue-easy-lightbox";
import {
  type CommentableResource,
  type CommentEntity,
  isFrame,
  isOrg,
  isUser,
  Organization,
  type UserEntity,
} from "~/src/api";
import { CommentTemplate } from "~/utils/templates/comment";
import NamiFile from "~/components/nami/NamiFile.vue";
import { nanoid } from "nanoid";
import { processImagePaste } from "~/utils/ui/pasteHandler";
import type NamiRichTextEditor from "~/components/nami/NamiRichTextEditor.vue";

interface Props {
  feedType: CommentableResource;
  feedId: string;
  parentId?: string;
  user?: UserEntity;
  showClose?: boolean;
  minTextboxHeight?: string;
  sync?: boolean;
}

defineComponent({
  inheritAttrs: false,
});

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "comment", v: CommentEntity): void;
}>();
const nuxtApp = useNuxtApp();
const translate = nuxtApp.$i18n.global.t;

const appStore = nuxtApp.$app();

const commentTemplate = reactive(
  new CommentTemplate(props.feedType, props.feedId),
);

watch(
  () => props.feedId,
  (newId) => (commentTemplate.feedId = newId),
);

const visibleRef = ref(false);
const fileInput = ref<HTMLInputElement>();
const richTextEditor = ref<InstanceType<typeof NamiRichTextEditor>>();
const spoilAttachment = ref(false);

function doAddFile() {
  if (commentTemplate.template.media) return;
  fileInput.value?.click();
}

function updateFile(file: File) {
  const localId = nanoid();

  if (commentTemplate.template.media?.blobUrl)
    URL.revokeObjectURL(commentTemplate.template.media.blobUrl);

  commentTemplate.template.media = {
    source: "local",
    filename: file.name,
    data: file,
    blobUrl: URL.createObjectURL(file),
    index: 0,
    id: localId,
    internalId: localId,
    progress: 0,
    failed: false,
  };
}

function updateFiles(images?: File[]) {
  const inputElement = fileInput.value;
  if (!inputElement && !images?.length)
    throw new Error("Could not find input element.");

  const file = images?.at(0) ?? Array.from(inputElement?.files ?? [])[0];

  if (inputElement) inputElement.value = "";
  if (file) {
    if (commentTemplate.template.media && images?.length) {
      appStore
        ?.prompt("This will overwrite your existing image. Paste anyways?", {
          icon: "confused",
          buttons: {
            keep: {
              buttonType: "primary",
              buttonText: "Keep",
            },
            overwrite: {
              buttonType: "secondary",
              buttonText: "Overwrite",
            },
          },
          forceCloseKey: "keep",
        })
        .then((action) => {
          if (action === "keep") return;
          updateFile(file);
        });
    } else updateFile(file);
  }
}

const clearAttachment = () => {
  if (!commentTemplate.template.media) return;
  URL.revokeObjectURL(commentTemplate.template.media.blobUrl);
  commentTemplate.template.media = undefined;
};

const { startAction, isPerformingAction, endAction } = useAction();
const uploadProgress = ref(0);

const submit = async () => {
  if (isPerformingAction.value) return;
  startAction();

  if (!props.user) {
    appStore?.openLoginRequiredModal();
    return endAction();
  }

  commentTemplate.template.parentId = props.parentId;

  await executeWithNotificationOnError(async () => {
    const token = await getTokenOrThrow();
    const comment = await commentTemplate.create(
      token,
      (e) => (uploadProgress.value = e.progress ?? 0),
    );

    const org = comment.relationships.find(isOrg);
    if (org) {
      const fetchedOrg = await Organization.get(org.id, []);
      comment.relationships = comment.relationships.filter((r) => !isOrg(r));
      comment.relationships.push({
        ...org,
        attributes: fetchedOrg.attributes,
      });
    }

    if (props.user) {
      comment.relationships = comment.relationships.filter((r) => !isUser(r));
      comment.relationships.push({
        type: "user",
        id: props.user.id,
        attributes: props.user.attributes,
      });

      const frame = props.user.relationships.find(isFrame);
      if (frame) comment.relationships.push(frame);
    }

    emit("comment", comment);
    richTextEditor.value?.reset();
  }).catch(() => {});

  const prevSelectedOrg = commentTemplate.template.organizationId;
  commentTemplate.reset(true);
  commentTemplate.template.organizationId = prevSelectedOrg;
  uploadProgress.value = 0;
  endAction();
};
</script>

<style scoped lang="postcss"></style>
